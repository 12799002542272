import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allPrismicBlogPost.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Image
        fluid={data.mast.childImageSharp.fluid}
        alt="Me"
        style={{
          maxWidth: `100%`,
          marginBottom: rhythm(2),
          marginLeft: `auto`,
          marginRight: `auto`,
          marginTop: 0,
        }}
      />
      <div style={{ margin: `auto`, maxWidth: rhythm(34) }}>
        <h1 style={{ textAlign: `center`, margin: `3rem 0` }}>Blog</h1>
      </div>
      <div style={{ margin: `auto`, maxWidth: rhythm(44) }} className="grid">
        {posts.map(({ node }) => {
          return (
            <article key={node.uid}>
              <header>
                <Link
                  style={{ display: `block`, boxShadow: `none` }}
                  to={node.uid}
                >
                  <Image
                    fluid={node.data.thumbnail.localFile.childImageSharp.fluid}
                    alt={node.data.title.text}
                    style={{
                      maxWidth: `460px`,
                      marginTop: 0,
                      marginBottom: `1rem`,
                      marginLeft: `auto`,
                      marginRight: `auto`,
                    }}
                  />
                </Link>
              </header>
              <section>
                <div className="categories">
                  {node.data.tags[0].tag.document.data.tag_name}
                </div>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                  <Link style={{ boxShadow: `none` }} to={node.uid}>
                    {node.data.title.text}
                  </Link>
                </h3>
                <small>{node.data.date}</small>
                <p>{node.data.blurb.text}</p>
                <p className="grid__link">
                  <Link
                    style={{ display: `block`, boxShadow: `none` }}
                    to={node.uid}
                  >
                    Read more
                  </Link>
                </p>
              </section>
            </article>
          )
        })}
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    mast: file(absolutePath: { regex: "/mast-blog.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    allPrismicBlogPost(sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          id
          data {
            date(formatString: "MMMM DD, YYYY")
            title {
              text
            }
            thumbnail {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 480, maxHeight: 300, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            blurb {
              text
            }
            tags {
              tag {
                document {
                  ... on PrismicTag {
                    data {
                      tag_name
                    }
                  }
                }
                slug
              }
            }
          }
          uid
        }
      }
    }
  }
`
